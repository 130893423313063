import React, { useContext, useEffect } from "react";
import { Context as AuthContext } from '../context/AuthContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import { Context as ProductContext } from '../context/ProductContext';
import { Context as OrderContext } from '../context/OrderContext';
import { Navigation } from '../components/navigation.js';
import { Cart } from "../components/cart.js";
import { Filter } from '../components/filter.js';
import ProductBox from '../components/ProductBox';
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'



const ProductScreen = () => {
  const { state: { id, name, rol, expoToken, load }, tryLocalSignin, setAnalytics } = useContext(AuthContext);
  const { state: { business, main_images, baseURL, isimage, imagefull, showtag, showcat, showbutton, business_id,
    isservice, rewards_days, ads, adsImage }, readConfig } = useContext(ConfigContext);
  const { state, readProduct, readProductMore, getProductDetail, searchProduct, readFavorites, updateFavorites } = useContext(ProductContext);
  const { state: { account_id, addItemHold, rewards_count }, addItem, getBasket, setSellOnline, getRewardsCount } =
    useContext(OrderContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (load === false) {
      tryLocalSignin({ business_id: 2 });
    } else {
      if (state.Search === '') {

        readProduct({ code: 2, account_id: id });
        readConfig(2);
        //readOffices({business_id:2});
        if (id !== null) {
          readFavorites({ account_id: id });
          //getRewardsCount({account_id:id});
        }
        setAnalytics({ id, code: 2, platform: 'Web' });

      }
      getBasket({ account_id: id, auto: true });
    }


  }, [load]);
  /*
      const faviconUpdate = async () => {
        let favicon = document.querySelector("link[rel~='icon']");
        favicon.href = icon;
      }
  */

  let slideImages = [];
  if (isimage && main_images !== null) {
    main_images.forEach(value => {
      slideImages.push({ url: baseURL + value, caption: '' });
    });
  };

  if (ads && adsImage !== null && (rol === 'User' || rol === null)) {
    adsImage.forEach(value => {
      slideImages.push({ url: baseURL + value, caption: '' });
    });
  };

  const CategoriesScroll = () => {
    return (
      <div className="product_categorie_scroll">
        {state.subCategories.map((item, key) => {
          if (item.image !== null) {
            return (
              <div key={key} className="product_categorie_block"
                onClick={() => navigate("/productcategorie/" + item.name, { state: { item } })
                }>
                <img src={state.baseURL + item.image} alt={''} className="productCategorie_image" />
                <div className="productCategorie_text">{item.name}</div>
              </div>
            );
          }
        }
        )}
      </div>
    );
  };

  return (
    <div className="App">

      {state.toggleFilter && <Filter />}
      {state.toggleCart && <Cart />}
      <Navigation />
      <div className="nav_subNav">
        <div className="nav_row_flex">
          <FaHome className="nav_sub_icon" />
          <AiOutlineRight className="nav_sub_icon" size={12} />
          <div className="nav_sub_title">Home</div>
        </div>
        {id !== null ?
          <div className="displayRowSignin" onClick={() => navigate("/account")}>
            <BiUser className="navBar__icons--user" />
            <div className="nav_account_right">Hola {name}!</div>
          </div>
        : <div className="nav_account_right">Bienvenido!</div>}
      </div>

      {state.Product.length === 0 &&
        <div className="style_full_height" />
      }
      {state.Search === '' &&
        <div>
          {CategoriesScroll()}
          {(isimage || (ads === true && (rol === 'User' || rol === null))) &&
            <div className="slide-container">
              <Slide>
                {slideImages.map((slideImage, index) => (
                  <div className="each-slide" key={index}>
                    <img src={slideImage.url} alt={''} className="productImageSlide"></img>
                  </div>
                ))}
              </Slide>
            </div>
          }

          <div className="displayRow">
            <div className="productTitle">NUEVOS PRODUCTOS </div>
            <div className="productMore"
              onClick={() => navigate("/productcategorie/Nuevos", { state: { item: 'new' } })}>Ver más {'>'}</div>
          </div>
          <div className="productList">
            {state.Product.filter(val => val.new === true).slice(0, 4).map((item) => {
              let favoritos = state.Favorites.find(val => val.product_id === item.id);
              if (favoritos === undefined) {
                favoritos = { id: null, product_id: item.id, account_id: account_id !== null ? account_id.id : id }
              }
              return (
                <div key={item.id} >
                  <ProductBox
                    item={item}
                    baseURL={state.baseURL}
                    getProductDetail={getProductDetail}
                    Favorites={favoritos}
                    updateFavorites={updateFavorites}
                    showtag={showtag}
                    type={'new'}
                  />
                </div>
              );
            })}
          </div>

          <div className="displayRow">
            <div className="productTitle">RELANZAMIENTOS</div>
            <div className="productMore"
              onClick={() => navigate("/productcategorie/Relanzamientos", { state: { item: 'renew' } })}>Ver más {'>'}</div>
          </div>
          <div className="productList">
            {state.Product.filter(val => val.renew === true).slice(0, 4).map((item) => {
              let favoritos = state.Favorites.find(val => val.product_id === item.id);
              if (favoritos === undefined) {
                favoritos = { id: null, product_id: item.id, account_id: account_id !== null ? account_id.id : id }
              }
              return (
                <div key={item.id} >
                  <ProductBox
                    item={item}
                    baseURL={state.baseURL}
                    getProductDetail={getProductDetail}
                    Favorites={favoritos}
                    updateFavorites={updateFavorites}
                    showtag={showtag}
                    type={'renew'}
                  />
                </div>
              );
            })}
          </div>
        </div>
      }

      {state.Categories.map((value, key) => {
        return (
          <div key={key}>

            <div>
              {state.subCategories
                .filter(val => val.categories_id === value.id).map((val, k) => {
                  let ProductFilter = state.Product.filter(v => v.subcategories_id === val.id).filter((v) => {
                    const itemData = v.name.toUpperCase();
                    const textData = state.Search.toUpperCase();
                    return itemData.indexOf(textData) > -1;

                  });
                  return (
                    <div key={k}>
                      {ProductFilter.length > 0 &&
                        <div>
                          <div className="displayRow">
                            <div className="productTitle">{val.name}</div>
                            <div className="productMore"
                              onClick={() => navigate("/productcategorie/" + val.name, { state: { item: val } })}>Ver más {'>'}</div>
                          </div>
                          <div className="productList">
                            {ProductFilter.slice(0, 4).map((item) => {
                              let favoritos = state.Favorites.find(val => val.product_id === item.id);
                              if (favoritos === undefined) {
                                favoritos = { id: null, product_id: item.id, account_id: account_id !== null ? account_id.id : id }
                              }
                              return (
                                <div key={item.id} >
                                  <ProductBox
                                    item={item}
                                    baseURL={state.baseURL}
                                    getProductDetail={getProductDetail}
                                    Favorites={favoritos}
                                    updateFavorites={updateFavorites}
                                    showtag={showtag}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      }
                    </div>
                  )
                })
              }
            </div>

          </div>
        )
      })}

      {(state.Search === '' && !state.load && state.page < state.subCategories.length) &&
        <div className="product_show_more"
          onClick={() => readProductMore({ code: business_id, page: state.page })}>Ver más</div>
      }
    </div>
  );
}

export default ProductScreen;