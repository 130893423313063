import React, { useContext } from "react";
import { Context as AuthContext } from '../context/AuthContext';
import { useNavigate } from "react-router-dom";
import UserForm from '../components/UserForm';
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";

const SignupScreen = () => {
    const {state, signup, resetMessage} = useContext(AuthContext);
    const item = {code:2, platform:'Web'};

    const navigate = useNavigate();

    const nav = () => {
        navigate("/", { replace: true });
    };

    return(
        <>
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon"/>
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title">Crear una Cuenta</div>
                </div>
            </div>
            <UserForm 
                item={item}
                onSubmit={signup}
                isAccount={false}
                isRegister={true}
                nav={nav}
                errorMessage={state.errorMessage}
                resetMessage={resetMessage}
            />
        </>
        
    )
}


export default SignupScreen;