import React from "react";

const TotalBox = ({Product, item, discount2, service, showService, secure, card, discount_corrida,sellonline,
    discount_money,fee,increaseMoney,notCre,secure_perc,secure_cant}) => {

        let subTotal = 0;
        let subTotal2 = 0;
        let discountTotal = 0;
        let discountCorridaTotal=0;
        let totalItems = 0;
        let newTotalItems = 0;
        let secureAmount = 0;
        item.forEach(value => {
            let product = Product.find(val => val.colors.find(val => val.id === value.productcolor_id));
            //let color = product.colors.find(val => val.id ===  value.productcolor_id);
            let price = value.price || product.price;
            if(increaseMoney > 0){
                price += increaseMoney;
            }
            if(value.plus){
                price += value.plus;
            }
    
            let discount = 0;
            if(value.discount >= 0){
                discount = value.discount;
            }else{
                discount = product.discount;
            }
    
            
            
            subTotal += (price * value.items);
            discountTotal += (((price * value.items) * discount) / 100);
            newTotalItems += value.items;
        });
        let total = subTotal - discountTotal;
    
        if(discount_money > 0){
            total = total - discount_money;
        }
    
        subTotal2 = total;
        total = total + service;


        if(secure){
            secureAmount = ((parseFloat(total) * secure_perc) / 100) + secure_cant
            total = parseFloat(total) + (((parseFloat(total) * secure_perc) / 100) + secure_cant)
        }
    
        let tc = (total * 0.0349) + 4 + (((total * 0.0349) + 4) * 0.16);
        if(card){
            total = total + tc;
        }
        
        let te;
        if(fee){
            te = (total * (fee / 100));
            total += te;
        }
        if(notCre > 0){
            total -= notCre;
        }
       
        const moneyFormat = (value) => {
            let val = '$' + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            return val;
        };

    return(
        <div className="totalBox">
            <div className="subTotal__wrapper">
                <div className="total__text">({newTotalItems} {newTotalItems > 1 ? ' Pares' : ' Par'})</div>
                <div className="total__value">{moneyFormat(subTotal)}</div>
            </div>
            {discount_money > 0 &&  
                <div className="subTotal__wrapper">
                    <div className="total__text">Descuento adicional:</div>
                    <div className="total__value">{moneyFormat(discount_money)}</div>
                </div>
            }
            {discountTotal > 0 &&
                <div className="subTotal__wrapper">
                    <div className="total__text">Descuento:</div>
                    <div className="total__value">{moneyFormat(discountTotal)}</div>
                </div>
            }

            {showService &&
            <>
                {subTotal2 !== total &&
                <div className="subTotal__wrapper subTotal_upperLine">
                    <div className="total__text">subTotal:</div>
                    <div className="total__value">{moneyFormat(subTotal2)}</div>
                </div>
                }
                
                {service > 0 &&
                <div className="subTotal__wrapper">
                    <div className="total__text">Costo de envío:</div>
                    <div className="total__value">{moneyFormat(service)}</div>
                </div>
                }

                {secure &&  
                 <div className="subTotal__wrapper">
                    <div className="total__text">Envió seguro:</div>
                    <div className="total__value">{moneyFormat(secureAmount)}</div>
                </div>
                }

                {card &&  
                 <div className="subTotal__wrapper">
                    <div className="total__text">TC:</div>
                    <div className="total__value">{moneyFormat(tc)}</div>
                </div>
                }

                {fee > 0 &&  
                 <div className="subTotal__wrapper">
                    <div className="total__text">%</div>
                    <div className="total__value">{moneyFormat(te)}</div>
                </div>
                }

                {notCre > 0 &&  
                 <div className="subTotal__wrapper">
                    <div className="total__text">Nota de Crédito</div>
                    <div className="total__value">{moneyFormat(notCre)}</div>
                </div>
                }
                
            </>
            }



            <div className="total__wrapper total_upperLine">
                <div className="total__text">Total:</div>
                <div className="total_Final_text">{moneyFormat(total)} MXN</div>
                {/*state.SelectedItems.reduce((acc, item) => {
                    return acc + item.quantity * item.price;
                }, 0)*/}
                
            </div>
            
            </div>
    );
};

export default TotalBox;