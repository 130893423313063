import createDataContext from './createDataContext';
import Api from '../api/Api';
//import { navigate } from '../navigationRef';

const addressReducer = (state, action) =>{
    switch(action.type){
        case 'read_address':
            return {...state, Address: action.payload, loadAdd: false };
        case 'read_address_id':
            return {...state, OrderAddress: action.payload };
        case 'add_address':
            const add = state.Address;
            add.push(action.payload);
            return {...state, Address:add};
        case 'update_address':
            const updated = state.Address.map(val => val.id === action.payload.id
                ? action.payload
                : val);
            return {...state, Address:updated};
        case 'delete_address':
            const deleted = state.Address.filter(val => val.id = action.payload.id);
            return {...state, Address:deleted};
        case 'read_offices':
            return {...state, Offices: action.payload, errorMessage:'' };
        case 'add_offices':
            const addOfficess = state.Offices;
            addOfficess.push(action.payload);
            return {...state, Offices:addOfficess};
        case 'update_offices':
            const updatedOffices = state.Offices.map(val => val.id === action.payload.id
                ? action.payload
                : val);
            return {...state, Offices:updatedOffices};
        case 'delete_offices':
            const deletedOffices = state.Offices.filter(val => val.id !== action.payload.id);
            return {...state, Offices:deletedOffices};
        case 'read_support':
            return {...state, OfficeSup: action.payload };
        case 'load':
            return {...state, loadAdd: true };    
        case 'read_officeById':
            return {...state, Office: action.payload };
        case 'error':
            return {...state, errorMessage: action.payload };
        default:
            return state;
    }
};

const readAddress =  (dispatch) => {
    return  async (req) =>{ 
        try{           
            dispatch({ type:'load' });
            const response = await Api.post('/readAddress', req);           
            dispatch({ type:'read_address', payload: response.data });
        }catch (err){
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al cargar direcciones \n('+err.message+').'});
        }
    };
};

const readAddressById =  (dispatch) => {
    return  async (req) =>{ 
        try{           
            const response = await Api.post('/readAddressById',req);           
            dispatch({ type:'read_address_id', payload: response.data });
        }catch (err){
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al cargar direcciones \n('+err.message+').'});
        }
    };
};

const addAddress =  (dispatch) => {
    return  async (req, nav, show) =>{ 
        try{           
            show(true);
            const response = await Api.post('/addAddress', req);          
            dispatch({ type:'add_address', payload: response.data });
            //navigate(nav);
            nav();
        }catch (err){
            show(false);
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al guardar direcciones \n('+err.message+').'});
        }
    };
};

const updateAddress =  (dispatch) => {
    return  async (req, nav, show) =>{ 
        try{       
            show(true);    
            await Api.put('/updateAddress', req);           
            dispatch({ type:'update_address', payload: req });
            nav();
        }catch (err){
            show(false);
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al actualizar la dirección \n('+err.message+').'});
        }
    };
};

const deleteAddress =  (dispatch) => {
    return  async (req) =>{ 
        try{           
            await Api.post('/deleteAddress', req);           
            dispatch({ type:'delete_address', payload: req });
        }catch (err){
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al eliminar la dirección \n('+err.message+').'});
        }
    };
};

const readOffices =  (dispatch) => {
    return  async (req) =>{ 
        try{           
            const response = await Api.post('/readOffices', req);        
            dispatch({ type:'read_offices', payload: response.data });
        }catch (err){
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al cargar sucursales \n('+err.message+').'});
        }
    };
};

const addOffices =  (dispatch) => {
    return  async (req, nav, show) =>{ 
        try{           
            show(true);
            const response = await Api.post('/addOffices', req);         
            dispatch({ type:'add_offices', payload: response.data });
            nav();
        }catch (err){
            show(false);
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al guardar sucursal \n('+err.message+').'});
        }
    };
};

const updateOffices =  (dispatch) => {
    return  async (req, nav, show) =>{ 
        try{           
            show(true);
            await Api.put('/updateOffices', req);           
            dispatch({ type:'update_offices', payload: req });
            nav();
        }catch (err){
            show(false);
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al actualizar la sucursal \n('+err.message+').'});
        }
    };
};

const deleteOffices =  (dispatch) => {
    return  async (req) =>{ 
        try{           
            await Api.post('/deleteOffices', req);           
            dispatch({ type:'delete_offices', payload: req });
        }catch (err){
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al eliminar la sucursal \n('+err.message+').'});
        }
    };
};

const readSupportOffice =  (dispatch) => {
    return  async () =>{ 
        try{           
            const response = await Api.get('/readSupportOffice');        
            dispatch({ type:'read_support', payload: response.data.offices_id });
        }catch (err){
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al cargar sucursales empleado \n('+err.message+').'});
        }
    };
};

const readOfficeById =  (dispatch) => {
    return  async (req) =>{ 
        try{           
            console.log(req);
            const response = await Api.post('/readOfficeById',req);           
            dispatch({ type:'read_officeById', payload: response.data });
        }catch (err){
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al cargar sucursal \n('+err.message+').'});
        }
    };
};

export const {Provider , Context} = createDataContext(
    addressReducer,
    {readAddress, readAddressById, addAddress, updateAddress, deleteAddress,
        readOffices, addOffices, updateOffices, deleteOffices, readSupportOffice, readOfficeById},
    { Address:[], SelectedAddress:{}, OrderAddress:{id:0}, errorMessage:'',Offices:[], 
        OfficeSup:null, loadAdd:false, Office: null}
);