import React, { useContext, useState } from "react";
import { Context as AuthContext } from '../context/AuthContext';
import { useNavigate } from "react-router-dom";
import logo from '../assets/logo.png';
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";

const LoginScreen = () => {
    const { state, signin, reset } = useContext(AuthContext);
    const [user, setUser] = useState({user: "", password: "", code:2, platform:'Web'});
    const [error, setError] = useState("");
    const [showActivity, setShowActivity] = useState(false);

    const navigate = useNavigate();

    const nav = () => {
        navigate("/", { replace: true });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
            if(user.user !== ""){
                if(user.password !== ""){
                    signin(user, nav, setShowActivity);
                }else{
                    setError('Escriba la contraseña!');
                    setTimeout(() => setError(''), 2500);
                }
            }else{
                setError('Escriba el usuario!');
                setTimeout(() => setError(''), 2500);
            }
      };
    
    const handleChange = ({ target: { value, name } }) => {
        setUser({ ...user, [name]: value });
        reset();
    }
    
    return(
        <div className="App">
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon"/>
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title">Inicia Sesión</div>
                </div>
            </div>
            <div className="list">
                <div className="signinCard">
                    <form onSubmit={handleSubmit}>
                        <div>
                            <img src={logo}  className="signinImage" alt="logo"/>
                        </div>
                        <div>
                            <input
                                type="number"
                                name="user"
                                id="user"
                                onChange={handleChange}
                                placeholder="Usuario"
                                className="input"
                            />
                        </div>
                        <div>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                onChange={handleChange}
                                placeholder="Contraseña"
                                className="input"
                            />
                        </div>
                        {showActivity && <div class="loader"></div>}
                        <div className="error_label">{state.errorMessage}</div>

                        <div>
                            <button
                                type="submit"
                                className="button"
                            >
                                Iniciar Sesión
                            </button>
                        </div>
                    </form>

                    <div className="displayStack_login">
                        <div className="textRegister1">¿Aún no tienes cuenta?</div>
                        <div className="textRegister2" onClick={() => {
                            reset();
                            navigate("/signup");
                        }}>Regístrate</div>
                    </div>
                    
                </div>
                {error !== '' &&
                <div className="toast__component">
                    <div className="add__cart--toast">
                        <div className="add__cart--text">{error}</div>
                    </div>    
                </div>
                }
            </div>       
      </div>
    )
}


export default LoginScreen;