import React, { useContext, useState } from "react";
import logo from '../assets/logo.png';
import { Context as AddressContext } from '../context/AddressContext';

import { FaPlus, FaMinus, FaTrash } from "react-icons/fa";
import { MdBusiness } from "react-icons/md";

const ProductBoxH = ({Product, item, addItem, URL, editable,deleteItem, discount2, increaseMoney,addItemHold,
    discount_corrida,corrida_array,deleteCorrida,sellonline,link,getProductDetail,rol,inv_inf}) => {
    const {state:{Offices}} = useContext(AddressContext);
    const [visible, setVisible] = useState({show:false,id:null});
    const [inputQty, setInputQty] = useState('');
    const [holding, setHolding] = useState(false);

    let product = Product; 
    //let color = product.colors.find(val => val.id ===  item.productcolor_id);
 
    if(product === undefined){
        return null;
    }else if(product.colors.find(value => value.sizes) === undefined){
        return null;
    }

    let color = product.colors.find(value => value.sizes.find(val => val.id === item.id));

    let size = color.sizes.find(value => value.id === item.id);
    let price = item.price || product.price;
    
    if(increaseMoney > 0){
        price += increaseMoney;
    }

    if(item.plus){
        price += item.plus;
    }
    
    let discount = item.discount || product.discount;
    let corrida = item.discount_corrida || discount_corrida; 
    //let finalPrice = price - ((price * discount) / 100); 
    let finalPrice = price - ((price * discount) / 100); 
    //finalPrice = discount2 ? finalPrice - ((finalPrice * discount2) / 100) : finalPrice;

    if(discount2){
      finalPrice = finalPrice - ((finalPrice * discount2) / 100);
    }
    let discoutFlag = (discount || discount2) ? true : false;

    const imagen = color.images[0] !== null 
        ? URL+color.images[0]
        : logo;


    const moneyFormat = (value) => {
        let val = '$' + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        return val;
    };



    return(
        <div className="cart__item">
                <img
                  src={imagen}
                  alt={''}
                  className="cart__item--image"
                ></img>
                <div className="cart__content--wrapper">
                  <div className="cart__namePrice--wrapper">
                    <div className="cart__item--name">{product.name}</div>
                    {color.visible 
                    ? <div className="cart__item--name">{color.name}</div>
                    : null}
                    {item.show_corrida 
                    ? <div className="cart_Row">
                        <div className="cart__item--price">Tallas: </div>
                        {corrida_array.map((val,key) => {
                              if(val.items > 0){
                                  return(<div key={key} className="cart__item--price">{color.sizes.find(v => v.id === val.id).size}, </div>);
                              }
                          })}
                      </div>
                    : <div className="cart__item--price">{size.size}</div>
                    }
                    {((rol !== null || rol !== 'User') && !item.show_corrida && Offices.name !== undefined) &&              
                    <div className="cart_Row">
                        <MdBusiness size={20} color="#e048db" />
                        <div className="cart__item--price">{Offices.find(val => val.id === size.offices_id).name}</div>
                    </div> 
                    }
  
                    <div className={discoutFlag ? "cart__item--discount" : "cart__item--price"}>{moneyFormat(price)} MXN</div>
                    {discoutFlag
                    ? <div className={"cart__item--price"}>{moneyFormat(finalPrice)} MXN</div>
                    : null
                    }
                  </div>

                  {editable  ?
                    item.show_corrida 
                    ? <div className="cart_Row">
                        <div className={"cart__item--price"}> {corrida_array.reduce((sum, value) => sum + value.items,0)} pz</div>
                        <div className={"cart__item--price2"}> {moneyFormat(finalPrice * corrida_array.reduce((sum, value) => sum + value.items,0))}</div>
                    </div>
                    :
                  <div className="cart__button--wrapper">
                    <div className="cart_align">
                    
                      <div className="box_quantity--container">
                        <FaMinus
                          className="cart__remove--item"
                          onClick={() =>
                            {if(!addItemHold && !holding){
                              setHolding(true);
                              addItem({select:item, add:-1, inv_inf});
                              setTimeout(() => setHolding(false), 600);
                          } } //dispatch({ type: "DECREASE_QUANTITY", payload: item })
                          }
                        />
                        <div className="cart__item--quantity">{item.items}</div>
                        <FaPlus
                          className="cart__add--item"
                          onClick={() =>
                            {if(!addItemHold && !holding){
                              setHolding(true);
                              addItem({select:item, add:1, inv_inf});
                              setTimeout(() => setHolding(false), 600);
                          } }//dispatch({ type: "INCREASE_QUANTITY", payload: item })
                          }
                        />
                      </div>
                          <div className="cart__item--price">{moneyFormat(finalPrice * item.items)} MXN</div>
                          {item.limitedMsg !== undefined && item.limitedMsg !== '' 
                            ? <div className="product_text_error">{item.limitedMsg}</div> : null}  
                    </div>
                    
                    
                    
                  </div>
                  : item.show_corrida 
                  ? <div className="cart_Row">
                      <div className={"cart__item--price"}> {corrida_array.reduce((sum, value) => sum + value.items,0)} pz</div>
                      <div className={"cart__item--price2"}> {moneyFormat(finalPrice * corrida_array.reduce((sum, value) => sum + value.items,0))}</div>
                  </div>
                  : <div className="cart_Row">
                      <div className={"cart__item--price"}> {item.items.toFixed(0)} pz</div>
                      <div className={"cart__item--price2"}> {moneyFormat(finalPrice * item.items)}</div>
                  </div>
                  }
                </div>
                {editable &&
                    <div 
                      onClick={() => {
                        if(item.show_corrida){
                          deleteCorrida({selectArray:corrida_array})
                        }else{
                            deleteItem({...item, inv_inf});
                        }}//cartHandler(item, cart)
                      }
                    >
                      <FaTrash className="cart_trash"/>
                    </div>
                    }
              </div>
    )
};

export default ProductBoxH;