import createDataContext from './createDataContext';
import Api from '../api/Api';


const authReducer = (state, action) =>{
    switch(action.type){
        case 'set_expo':
            return {...state, token: action.payload };   
        case 'signup':
            return state;
        case 'signin':
            return { 
                errorMessage: '', 
                token: action.payload.token, 
                id: action.payload.id, 
                rol: action.payload.rol,
                name: action.payload.name,
                expoToken: action.payload.expoToken,
                load:true
            };
        case 'signout':
            return { errorMessage: '' }
        case 'expoToken_dummy':
            return { ...state, expoToken: action.payload }
        case 'load':
            return { ...state, load: true }
        case 'error':
            return { ...state, errorMessage: action.payload }
        case 'reset':
            return { ...state, errorMessage: '' }
        case 'readUserData':
            return { ...state, UserData: action.payload }
        default:
            return state;

    }
};

const tryLocalSignin = (dispatch) => {
    return async (req) =>{
        const obj = localStorage.getItem("eCommerce_ReactJS_002");
        const item = JSON.parse(obj);
        
        if(item !== null){
            if(req.business_id === item.business_id){
                dispatch({ type:'signin', payload: {token: item.token, id: item.id, rol: item.rol, 
                    name: item.name, expoToken:item.expoToken, business_id: item.business_id}});
            }else{
                dispatch({type:'load'});
            }
        }else{
            dispatch({type:'load'});
        }     
    };
};

const signup =  (dispatch) => {
    return  async (req, nav, show) =>{        
        try{         
            show(true);  
            const response = await Api.post('/signup', req);
            const obj = {token: response.data.token, id: response.data.id, rol: response.data.rol,
                name: response.data.name,  business_id:response.data.business_id};
            localStorage.setItem('eCommerce_ReactJS_002', JSON.stringify(obj));

            dispatch({ type:'signin', payload: {
                token: response.data.token, 
                id: response.data.id,
                rol: response.data.rol,
                name: response.data.name,
                business_id: response.data.business_id
            } });             
            nav(); 
        } catch (error) {
            show(false);
            if (error.response) {
                dispatch({ type: 'error', payload: error.response.data.error });
            }
        }
    };
};

const signin =  (dispatch) => {
    return  async (req, nav, show) =>{ 
        try{ 
            show(true);
            const response = await Api.post('/signin', req);
            
            const obj = {token: response.data.token, id: response.data.id, rol: response.data.rol,
                        name: response.data.name, business_id:response.data.business_id};
            localStorage.setItem('eCommerce_ReactJS_002', JSON.stringify(obj));

            dispatch({ type:'signin', payload: {
                token: response.data.token, 
                id: response.data.id,
                rol: response.data.rol,
                name: response.data.name,
                business_id: response.data.business_id
            } });
            nav();
        } catch (error) {
            show(false);
            if (error.response) {
                dispatch({ type: 'error', payload: error.response.data.error });
            }
        }
    };
};

const signout = (dispatch) => {
    return async (req, nav) =>{
        try{
            localStorage.removeItem('eCommerce_ReactJS_002');
            dispatch({ type:'signout'});
            nav();
        } catch (error) {
            if (error.response) {
                dispatch({ type: 'error', payload: error.response.data.error });
            }
        }
    
    };
};

const unregistered = (dispatch) => {
    return async (req) =>{   
        try{   
            //await Api.post('/unregistered', req);
            dispatch({ type:'set_expo', payload:req.expoToken});
        }catch (err){
            //console.log('Error al registrar token \n('+err.message+').');
        }
    };
};

const setAnalytics = (dispatch) => {
    return async (req) =>{   
        try{   
            await Api.post('/setAnalytics', req);
        }catch (err){
        }
    };
};

const reset = (dispatch) => {
    return async () => {
        try {
            dispatch({ type: 'reset' });
        } catch (err) {
        }
    };
};


const readUserData = (dispatch) => {
    return async (req, nav) => {
        try {
            const response = await Api.post('/readUserData', req);
            dispatch({ type: 'readUserData', payload: response.data });

        } catch (error) {
            if (error.response) {
                dispatch({ type: 'error', payload: error.response.data.error });
            }
        }

    };
};

export const { Provider, Context } = createDataContext(
    authReducer,
    { signin, signup, unregistered, tryLocalSignin, signout, setAnalytics, reset, readUserData },
    {
        token: null, id: null, rol: null, name: '', errorMessage: '', expoToken: null, business_id: null, load: false,
        UserData: null
    }
);
