import { useContext, useEffect, useState } from "react";
import { Context as OrderContext } from '../context/OrderContext';
import { Context as ProductContext } from '../context/ProductContext';
import { Context as AuthContext } from '../context/AuthContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import ProductBoxH from '../components/ProductBoxH';
import TotalBox from '../components/TotalBox';
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

export function Cart() {
  const {state, addItem2, deleteItem, setServices, setAccount, getBasket,setSellOnline,
    deleteCorrida, deleteAllItems,getRewardsCount,setIncreaseMoney,setDiscountMoney} = useContext(OrderContext);
  const {state:{Product, baseURL, load}, getProductDetail, readProductBasket, setToggleCart} = useContext(ProductContext);
  const { state: { id, rol, name, expoToken } } = useContext(AuthContext);
  const {state:{business,iswholesale,wholesale, isservice,service,discount_corrida,
      sellonline,expire_days, rewards_days,inv_inf}} = useContext(ConfigContext);

  const [Mayoreo, setMayoreo] = useState(true);
  const [discount1, setDiscount1] = useState(false);
  const [discount2, setDiscount2] = useState(false);

  
  const [input, setInput] = useState(0);
  const handleChange = ({ target: { value, name } }) =>
    setInput(value);

    let render = false;
  useEffect(() => {
    if(render === false){
      let getId; 
      if(state.account_id !== null){
        getId = state.account_id.id;
      }else{
        getId = id;
      }
      //getBasket({account_id:getId, auto:true});
      readProductBasket({ account_id: getId, expoToken }, getBasket);
    }
    render = true;
  },[]);

  const navigate = useNavigate();


  let color_id;
  let totalItems = state.SelectedItems.reduce((sum, value) => ((typeof value.items == "number" ) 
    //&& value.show_corrida == null
    && !value.prod_disc
    ? sum + value.items : sum), 0);

    let getDiscount = iswholesale ? wholesale.filter(val => val.wquantity <= totalItems).reverse().find(val => 
        totalItems >= val.wquantity) : undefined;
    if(!Mayoreo){
        getDiscount = undefined;
    }
    let discount = getDiscount ? getDiscount.wdiscount : undefined;

  
    let weight = 0;
    let serv = 0;
    let value = 0;
    let subTotalCorrida = 0;
    let total = 0;
    let revision = false;
    state.SelectedItems.forEach(value => {  
        let product = Product.find(val => val.colors.find(val => val.id === value.productcolor_id));
        weight = weight + (product.weight * value.items);
        let productPrice = product.price;
        if(state.increaseMoney !== null){
            productPrice += state.increaseMoney;
        }
        let findPlus = state.Plus.find(val => val.id_basket === value.id_basket);
        if(findPlus){
            value.plus = findPlus.plus;
            productPrice += findPlus.plus;
        }
        
        let finalPrice = ((product.price * value.items) -  (((product.price * value.items) * product.discount) / 100));

        total += finalPrice;
        
        if(product.revision){
          revision = true;
        }
    });

    if(isservice){
        let highService = service[service.length -1].sf;
        let rounds = Math.ceil(weight/highService);
        for(let i = 1; i <= rounds; i++){          
            if(weight > highService){
                value = highService;
                weight = weight - highService;
            }else{
                value = weight;
            }     
            let getService = service.find(val => val.si < value && val.sf >= value);
            //serv = getService != undefined ? serv + getService.sc : 0;  
            if(getService !== undefined){
              serv = serv + getService.sc;
            }else{
              serv = 0;
            }       
        }
    }
    
  
    if(discount){
      total = total - ((total * discount) / 100);
    }

    if(state.discountMoney !== 0){
      total = total - state.discountMoney;
    }

    let comments = null;
    if(state.rewards_count === rewards_days && rewards_days > 0){
        serv = 0;
        comments = 'Rewards: Envío gratis';
    }
    //total = total + serv;
    if(state.SelectedItems.length > 0){
      total = parseFloat(total).toFixed(0);
      let msDiff = new Date().getTime() - new Date(state.SelectedItems[0].created_on).getTime();
      let date = msDiff / (1000 * 60 * 60 * 24);
      let countdown = parseInt(parseInt(expire_days) - date);
      let time = Math.floor(msDiff / (1000 * 60 * 60 )) ;
      time = ((parseInt(expire_days) - countdown) * 24) - time;
      let dateMsj = '';
      if(countdown === 0){
          time = time < 0 ? 0 : time;
          dateMsj = time +' horas'
      }else if(countdown === 1){
          dateMsj = countdown +' día '+time +' horas'
      }else{
          dateMsj = countdown +' días '+time +' horas'
      }
    }

  return (
    <div className="cart__wrapper">
      <div
        className="cart__background"
        onClick={() => setToggleCart()
          //dispatch({ type: "TOGGLECART" })
        }
      ></div>
      <div className="cart__content">
        <div className="cart__nav">
          <div className="cart__header">Carrito de compras</div>
          <FaTimes
            className="cart__close"
            onClick={() => setToggleCart()
              //dispatch({ type: "TOGGLECART" })
            }
          />
        </div>
        {state.SelectedItems.length > 0 &&
        <div>
          {/*(rol === 'Admin' || rol === 'Super' || rol === 'Support') &&
          <div className="cart_displayRow_center">
            <div className="cart_float_button" onClick={() => {setDiscount1(!discount1);setDiscount2(false);}}>
              <div className="cart_float_text">Aumento</div>
              <div className="cart_float_text">Descuento</div>
            </div>
            <div className="cart_float_button" onClick={() => {setDiscount2(!discount2);setDiscount1(false);}}>
              <div className="cart_float_text">Descuento</div>
              <div className="cart_float_text">Global</div>
            </div>
            <div className={Mayoreo ? "cart_float_button" : "cart_float_button_un"}
              onClick={() => setMayoreo(!Mayoreo)}>
              <div className="cart_float_text">Mayoreo</div>
              <br/>
            </div>
          </div>
        */}
          {(discount1 || discount2) &&
          <div>
            <div className="cart__counter">{discount1 && 'Aumento/Descuento para todos los productos.'}</div>
            <div className="cart__counter">{discount2 && 'Descuento adicional.'}</div>
            <div className="cart_displayRow">
              <input
                  type="number"
                  name="input"
                  id="input"
                  onChange={handleChange}
                  placeholder="$0.00"
                  className="cart_input"
              />
              <div className={"cart_float_button"}
                onClick={() => {
                  if(discount1){
                    setIncreaseMoney({input:parseFloat(input)});
                    setDiscount1(false);
                  }
                  if(discount2){
                    setDiscountMoney({input:parseFloat(input)});
                    setDiscount2(false);
                  }
                }}>
                <div className="cart_float_text">Aplicar</div>
              </div>
            </div>
          </div>
          }
          {/*
          <div className="cart_displayRow_center">
            <div className="cart_delete_all" onClick={() => deleteAllItems({account_id:state.account_id != null 
              ? state.account_id.id : id})}>Borrar todo</div>
          </div>
          */}
        </div>
        }
        
        <div className="cart__list">
          {state.SelectedItems.map((item,key) => {

            item.account_id = state.account_id === null ? id : state.account_id.id;
            let flag = item.show_corrida && color_id === item.productcolor_id ? false : true;
            color_id = item.show_corrida ? item.productcolor_id : null;
            let corrida_array = [];
            if(item.show_corrida){
                corrida_array = state.SelectedItems.
                    filter(val => val.productcolor_id === item.productcolor_id && val.show_corrida);
            }

            return (
              <div>
              {flag &&
              <ProductBoxH key={key}
                Product={Product.find(value => value.colors
                .find(val => val.id === item.productcolor_id))}
                item={item}
                addItem={addItem2}
                addItemHold={state.addItemHold} 
                URL={baseURL}
                editable={true}
                deleteItem={deleteItem}
                discount2={discount}
                discount_corrida={discount_corrida} 
                increaseMoney={state.increaseMoney}
                corrida_array={corrida_array}
                deleteCorrida={deleteCorrida}
                sellonline={state.sellOnline ? sellonline : 0}
                link={true}
                getProductDetail={getProductDetail}
                rol={rol}
                inv_inf={inv_inf}
              />
              }
              </div>
            );
          })}
        </div>
        {(() => {
          if (state.SelectedItems.length === 0) {
            return (
              <div className="cart__empty--list">
                No tienes productos en el carrito.
              </div>
            );
          } else {
            return (
              <>
                <TotalBox
                      Product={Product}
                      item={state.SelectedItems}
                      discount2={discount}
                      discount_corrida={discount_corrida}
                      sellonline={state.sellOnline ? sellonline : 0}
                      service={0}
                      showService={false}
                      discount_money={state.discountMoney}
                      increaseMoney={state.increaseMoney}
                  />

                <div className="primary__button cart"
                onClick={() => {
                  setServices({service:serv, discount, amount:total, revision, comments});
                  setToggleCart();
                  navigate("/confirmation");
                }}
                >Continuar</div>
              </>
            );
          }
        })()}
      </div>
    </div>
  );
}
