import { useContext, useState } from "react";
import { Context as AuthContext } from '../context/AuthContext';
import { Context as ProductContext } from '../context/ProductContext';
import { Context as OrderContext} from '../context/OrderContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import { BsSearch, BsBag, BsWhatsapp, BsGear, BsCart2, BsHeart } from "react-icons/bs";
import { BiUser } from "react-icons/bi";
import { AiOutlineMenu, AiOutlineScan } from "react-icons/ai";
import { useMediaQuery } from "react-responsive";
//import { useDataProvider } from "../context.js";
import logo from '../assets/logo2.png';

import { useNavigate } from "react-router-dom";

export function Navigation() {
  const { state: { id, name, rol } } = useContext(AuthContext);
  const { setToggleCart, setToggleFilter } = useContext(ProductContext);
  const { state: { SelectedItems, newOrderNoti } } = useContext(OrderContext);
  const { state: { business } } = useContext(ConfigContext);
  const isMobile = useMediaQuery({ query: "(max-width: 825px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 826px)" });
  const [searchToggle, setSearchToggle] = useState(isDesktop ? true : false);
  //const { cart, wishlist, dispatch } = useDataProvider();

  const navigate = useNavigate();
  const totalItems = SelectedItems.reduce((sum, value) => ((typeof value.items == "number")
    ? sum + value.items : sum), 0);
  return (
    <div className="navContainer">
      <div className="navBar">
      {/* 
        <img
          src= {zapatillasMay}
          alt="placeholder logo"
          className="navBar__logo"
        ></img>
      */}
        <AiOutlineMenu className="navBar__icons--home"
            onClick={() => {
              setToggleFilter();
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
        <div className="headerName">
          {id != null 
            ? <>{isDesktop && 'Hola '} {name}!</>
            : <>Bienvenido!</>}
        </div>
        
        {isDesktop && <SearchView searchToggle={searchToggle} />}

        <div className="navBar__icons">
          {isMobile &&
          <BsSearch
            className="navBar__icons--search"
            onClick={() => setSearchToggle(!searchToggle)}
          />
          }
          

          {/*isMobile && (
            <FaFilter
              className="navBar__icons--menu"
              onClick={() => dispatch({ type: "TOGGLEFILTER" })}
            />
          )
          <div className="wishlist__wrapper">
            <FaRegBookmark
              className="navBar__icons--wishlist"
              onClick={() => {
                dispatch({ type: "TOGGLEWISHLIST" });
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            />
            <div className="badge__number">{wishlist.length}</div>
          </div>
          */}

            
          {id != null &&
            <div className="cart__wrapper">
              <BsBag
                className="navBar__icons--menu"
                onClick={() => navigate("/orders")}
              />
              {newOrderNoti === true && <div className="badge__number_order">new</div>}
            </div>
          }
          {id != null && 
            <BsHeart 
              className="navBar__icons--menu"
              onClick={() => navigate("/product_favorite")}/>
          }
          
          {id != null ?
          <div className="cart__wrapper">
            <BsCart2
              className="navBar__icons--cart"
              onClick={() => {
                //dispatch({ type: "TOGGLECART" });
                setToggleCart();
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            />
            <div className="badge__number">{SelectedItems.reduce((sum, value) => 
            ((typeof value.items == "number" ) 
            ? sum + value.items : sum), 0)}</div>
          </div>
          : <div className="displayRowSignin" onClick={() => navigate("/signin")}>
            <BiUser className="navBar__icons--cart"/>
            <div className="headerSignin">Inicia sesión</div>
          </div> 
          }

          <BsGear className="navBar__icons--cart"
            onClick={() => navigate("/account")}
          />

        </div>
      </div>
      {isMobile && <SearchView searchToggle={searchToggle} />}
    </div>
  );
}

function SearchView({ searchToggle }) {
  //const { searchInput, dispatch } = useDataProvider();
  const {state:{Search}, setSearch, searchProduct} = useContext(ProductContext);
  const {state:{business_id}} = useContext(ConfigContext);
  const { state: { id } } = useContext(AuthContext);
  return (
    <>
    {searchToggle &&
    <div className="searchBar" style={{ opacity: searchToggle ? 1 : 0 }}>
      <div className="searchBar__input--wrapper">
        <input
          type="search"
          className="searchBar__input"
          id="searchBar__input"
          value={Search}
          pattern=".{50,}"
          onChange={(e) =>{
            //dispatch({ type: "SEARCH_INPUT", payload: e.target.value })
            setSearch(e.target.value);
            if(e.target.value.length > 2){
                  searchProduct({ val: e.target.value, code: business_id, account_id: id });
            }
          }}
        ></input>
        <div className="searchBar__line"></div>
        <label htmlFor="searchBar__input" className="searchBar__placeholder">
          Busque aqui...
        </label>
      </div>
    </div>
    }
    </>
    
  );
}
