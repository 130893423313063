import React, { useContext, useEffect, useState } from "react";
import { Context as AuthContext } from '../context/AuthContext';
import { Context as ProductContext } from '../context/ProductContext';
import { Context as OrderContext } from '../context/OrderContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import { Navigation } from '../components/navigation';
import { Cart } from '../components/cart';
import { Filter } from '../components/filter.js';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { BsCircleFill } from "react-icons/bs";
import { FaPlus, FaMinus, FaTrash, FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";
import Modal from 'react-modal';

const ProductDetailScreen = () => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const { state: { Product, baseURL, Favorites, toggleCart, toggleFilter, load, subCategories },
        readProduct, getProductDetail } = useContext(ProductContext);
    const { state, addItem2, getBasket } = useContext(OrderContext);
    const { state: { iswholesale, wholesale, sellonline, lock_less, inv_inf, business  }, readConfig } = useContext(ConfigContext);

    const { state: { id, rol }, tryLocalSignin } = useContext(AuthContext);
    const item = Product.find(value => value.id === parseInt(params.id));//location.state.id

    let reloading;
    let findColor = undefined;
    if (item !== undefined) {
        findColor = item.colors.find(val => val.id);
        reloading = item.reload;
    }

    const [color, setColor] = useState(findColor);
    const [select, setSelect] = useState(null);
    const [office, setOffice] = useState(null);

    //const [error, setError] = useState({});
    const [imageVisible, setImageVisible] = useState(0);
    const [visible, setVisible] = useState(false);

    const [holding, setHolding] = useState(false);
    const [messageSubmit, setMessageSubmit] = useState('');

    const [modal, setModal] = useState(false);
    const [inputQty, setInputQty] = useState('');



    useEffect(() => {
        window.scrollTo(0, 0);
        if (item !== undefined) {
            if (findColor.sizes !== undefined) {
                findColor = item.colors.find(val => val.sizes.find(v => v.quantity > 0));
                let findSize = findColor !== undefined ? findColor.sizes.find(val => val.quantity > 0) : null;
                if (id === null || rol === 'User') {
                    findSize = findColor !== undefined ? findColor.sizes.find(val => val.quantity > 0) : null;
                }
                if (findColor !== undefined) {
                    setColor(findColor);
                    setSelect(findSize);
                    setOffice(findSize !== undefined ? findSize.offices_id : null);
                }
                facebookViewContent();
            }
        }
        if (item === undefined && load === false) {
            tryLocalSignin({ business_id: 2 });
            readProduct({ code: 2, account_id: id });
            readConfig(2);
            setTimeout(() => getProductDetail({ id: parseInt(params.id) }), 1500);
            getBasket({ account_id: id, auto: true });
        }
    }, [reloading]);

    if (item === undefined) {
        return <div></div>;
    }

    let Selected = null;
    if (select !== null && select !== undefined) {
        Selected = state.SelectedItems.find(val => val.id === select.id
            && (val.show_corrida === null || val.show_corrida === undefined));
    }


    /*
        let Selected = select !== null 
            ? state.SelectedItems.find(val => val.id === select.id && val.show_corrida === null) 
            : null;
    
        let selectArray = select !== undefined 
            ? state.SelectedItems.filter(val => val.productcolor_id === select.productcolor_id && val.show_corrida === true)
            : [];
        
     */
    let Colors = [];
    item.colors.forEach(value => {
        if (value.sizes !== undefined) {
            if (value.sizes.reduce((sum, val) => sum + val.quantity, 0) > 0) {
                Colors.push(value);
            }
        }
    });

    let Images = [];
    let ImagesView = [];
    if (color !== null && color !== undefined) {
        color.images.forEach(val => {
            Images.push(baseURL + val);
            ImagesView.push({ uri: baseURL + val });
        });

    }


    const sellOnLine = state.sellOnline ? sellonline : 0;

    var totalItems = state.SelectedItems.reduce((sum, value) =>
    ((typeof value.items === "number")//&& value.product_id == item.id) 
        //&& value.show_corrida == null
        && !value.prod_disc
        ? sum + value.items : sum), 0);


    let reverseW = iswholesale ? wholesale.filter(val => val.wquantity <= totalItems) : undefined;
    //let nextDiscount = iswholesale ? wholesale.find(val => totalItems < val.wquantity) : undefined;
    let getDiscount = iswholesale ? reverseW.reverse().find(val => totalItems >= val.wquantity) : undefined;

    //let finalPrice = item.price - ((item.price * item.discount) / 100);
    //getDiscount ? finalPrice = finalPrice - ((finalPrice * getDiscount.wdiscount) / 100) : null;
    let finalPrice = item.price - ((item.price * item.discount) / 100);

    if (getDiscount !== undefined) {
        finalPrice = finalPrice - ((finalPrice * getDiscount.wdiscount) / 100)
    }
    //finalPrice = finalPrice + ((finalPrice * sellOnLine) / 100);


    let favoritos = Favorites.find(val => val.product_id === item.id);

    if (favoritos === undefined) {
        favoritos = { id: null, product_id: item.id, account_id: id }
    }
    /*
        const toggleOverlay = () => {
            setInputQty('');
            setVisible(false);
        };
    */
    /*
        const [visible2, setVisible2] = useState({visible:false});
        const [input, setInput] = useState(1);
        const toggleOverlay2 = () => {
            setInput('');
            setVisible2({visible:false});
        };
    
        const handleChange = ({ target: { value } }) =>
            setInput( value );
    */
    let finSubCategorie = subCategories.find(val => val.id === item.subcategories_id);

    const facebookViewContent = () => {
        window.fbq('track', 'ViewContent', {
            content_ids: [params.id],
            content_type: 'product',
            id: params.id,
            content_name: item.name,
            title: item.name,
            description: item.description,
            link: 'https://mauriciomares.com/productdetail/' + params.id,
            image_link: Images[0],
            brand: business[0].name,
            availability: item.quantity,
            condition: true,
            price: item.price,
            value: item.price,
            currency: 'MXN'
        });

       

    }


    const handleChange = ({ target: { value } }) =>
        setInputQty(value);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const moneyFormat = (value) => {
        let val = '$' + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' MXN';
        return val;
    };



    return (
        <div className="App">
            {toggleCart && <Cart />}
            {toggleFilter && <Filter />}
            <Navigation />
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon" />
                    <AiOutlineRight className="nav_sub_icon" size={12} />
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                {finSubCategorie !== undefined &&
                    <div className="nav_row">
                        <AiOutlineRight className="nav_sub_icon" size={12} />
                        <div className="nav_sub_title" onClick={() =>
                            navigate("/productcategorie/" + finSubCategorie.name, { state: { item: finSubCategorie }, replace: true })}>{finSubCategorie.name}</div>
                    </div>
                }
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12} />
                    <div className="nav_sub_title">{item.name}</div>
                </div>
            </div>
            <>
                <title>{item.name}</title>
                <meta property="og:description" content={item.description} />
                <meta property="og:title" content={item.name} />
                <meta property="og:image" content={Images[0]} />
                <meta property="og:url" content={'https://mauriciomares.com/productdetail/' + params.id} />
                <meta property="product:retailer_item_id" content={item.id} />
                <meta property="product:price:amount" content={item.price} />
                <meta property="product:price:currency" content="MXN" />
                <meta property="product:availability" content="available for order" />

            </>
            <div className="productDetailList">
                <div className="productDetailCardImage">
                    <div>
                        {/*<img src={Images[0]} alt={''} className="productDetailImage"></img>*/}
                        {Images.map((value, key) => {
                            return (
                                <div key={key}>
                                    {imageVisible === key &&
                                        <img src={value} alt={''} className="productDetailImage" />
                                    }
                                </div>
                            );
                        })}
                        <div className="row1">
                            {Images.map((value, key) => {
                                return (
                                    <div key={key} className="column1" onClick={() => setImageVisible(key)}>
                                        <img src={value} alt={''} className="productDetailImageSmall" />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>


                <div className="productDetailCardInfo">
                    <div className="productCard__content">
                        <div className="displayRow">
                            <h3 className="productDetailName">{item.name}</h3>
                            <h3 className="productDetailColor">{color != null ? color.name : null}</h3>
                        </div>



                        <div className="displayleft">
                            {item.discount > 0 &&
                                <div className="productPrice">{moneyFormat(item.price)}</div>
                            }
                            <div className="displayRow">
                                <div className="productDetailPrice">{moneyFormat(finalPrice)}</div>
                                {getDiscount !== undefined &&
                                    <div className="productTime"> -{getDiscount.wdiscount}%</div>
                                }
                            </div>
                        </div>

                        <div className="displayStack__1">
                            <div className="productSubTitleGray">Color: {color != null ? color.name : null}</div>
                        </div>
                        <div className="displayStack__2">
                            <div className="productRating">
                                {Colors.map((value, index) => (
                                    <div key={index} className="product_circle_color_detail" style={{ backgroundColor: value.color }}
                                        onClick={() => {
                                            let find = item.colors.find(v => v.id === value.id);
                                            setColor(find);
                                            let findSize = find.sizes.find(val => val.quantity > 0);
                                            if (id === null || rol === 'User') {
                                                findSize = find.sizes.find(val => val.quantity > 0 && val.offices_id !== 2);
                                            }
                                            setSelect(findSize);
                                            setOffice(findSize !== undefined ? findSize.offices_id : null);
                                        }}
                                    />
                                ))}
                            </div>
                        </div>

                        <div className="displayRow">
                            {(color !== undefined && select !== null)
                                ? color.sizes != null &&
                                color.sizes.filter(value => value.offices_id === office).map((value, key) => {
                                    return (
                                        <>
                                            {(value.quantity > 0 && select.size !== 'Unitalla' && select.size !== '0') &&
                                                <div className={select.id === value.id ? "boxBorderSelected" : "boxBorder"}>
                                                    <div key={key} className="productDetailSize" onClick={() => {
                                                        //value.product_id = item.id;                                   
                                                        //setError({size:''});
                                                        setSelect(value);
                                                    }}>{value.size}</div>
                                                </div>
                                            }
                                        </>
                                    )
                                })
                                : null}
                        </div>

                       
                        {Selected != null
                            ? <div className="ProductDetailSelected">

                                <div className="displayView">
                                    <div className="productSubTitle">Talla {select.size}</div>

                                    <div>
                                        {Selected.limitedMsg !== undefined && Selected.limitedMsg !== ''
                                            ? <div className="product_text_error">{Selected.limitedMsg}</div>
                                            : null
                                        }
                                        <div className="displayView">
                                            <div className="box_quantity--container">
                                                {rol === 'User' && lock_less
                                                    ? null
                                                    :
                                                    <FaMinus
                                                        className="cart__remove--item"
                                                        onClick={() => {
                                                            if (!state.addItemHold && !holding) {
                                                                setHolding(true);
                                                                addItem2({ select: Selected, add: -1, inv_inf });
                                                                setTimeout(() => setHolding(false), 1000);
                                                            }
                                                        } //dispatch({ type: "DECREASE_QUANTITY", payload: item })
                                                        }
                                                    />
                                                }
                                                <div className="cart__item--quantity" onClick={() => {
                                                    setInputQty(Selected.items);
                                                    setModal(!modal);
                                                }}>
                                                    {Selected.items}
                                                </div>
                                                <FaPlus
                                                    className="cart__add--item"
                                                    onClick={() => {
                                                        if (!state.addItemHold && !holding) {
                                                            setHolding(true);
                                                            addItem2({ select: Selected, add: 1, inv_inf });
                                                            setTimeout(() => setHolding(false), 1000);
                                                        }
                                                    }//dispatch({ type: "INCREASE_QUANTITY", payload: item })
                                                    }
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="productSubTitle">{moneyFormat(finalPrice * Selected.items)}</div>
                                </div>

                                <Modal
                                    isOpen={modal}
                                    onRequestClose={() => setModal(!modal)}
                                    style={customStyles}
                                >
                                    <h1>Especificar cantidad</h1>
                                    <div className="productSubTitle">En talla {Selected.size}</div>
                                    <input
                                        className="product_input"
                                        type="number"
                                        name="input"
                                        id="input"
                                        value={inputQty}
                                        autoFocus
                                        onChange={handleChange}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                setModal(!modal);
                                                setInputQty('');
                                                if (inputQty !== '') {
                                                    addItem2({ select: Selected, add: parseFloat(inputQty), input: true, inv_inf });
                                                }
                                            }
                                        }}
                                    />
                                    <div className="product_btn"
                                        onClick={() => {
                                            setModal(!modal);
                                            setInputQty('');
                                            if (inputQty !== '') {
                                                addItem2({ select: Selected, add: parseFloat(inputQty), input: true, inv_inf });
                                            }
                                        }}> ACEPTAR</div>
                                </Modal>

                            </div>
                            :
                            <div className="primary__button detail" onClick={() => {
                                if (id != null) {
                                    if (select !== undefined) {
                                        if (id !== null) {
                                            select.account_id = state.account_id === null ? id : state.account_id.id;


                                            if (item.discount > 0) {
                                                select.prod_disc = true;
                                            }
                                            if (!state.addItemHold && !holding) {
                                                setHolding(true);
                                                if (select.quantity > 0) {
                                                    addItem2({ select, add: 1, inv_inf });
                                                }
                                                setTimeout(() => setHolding(false), 1000);
                                            }
                                    window.fbq('track', 'AddToCart', {
                                        content_ids: [item.id],
                                        content_type: 'product',
                                        content_name: item.name,
                                        id: item.id,
                                        title: item.name,
                                        description: item.description,
                                        link: 'https://mauriciomares.com/productdetail/params.id',
                                        image_link: Images[0],
                                        brand: business[0].name,
                                        availability: item.quantity,
                                        condition: true,
                                        price: item.price,
                                        value: item.price,
                                        currency: 'MXN'
                                    });
                                        }
                                    } else {
                                        //setError({size:'* Seleccione una talla'});
                                        setMessageSubmit('Seleccione una talla');
                                        setTimeout(() => setMessageSubmit(''), 2500);
                                    }
                                } else {
                                    //alert('Alerta!','Para comenzar a comprar debes primero iniciar sesión!');
                                    setMessageSubmit('Para comenzar a comprar debes primero iniciar sesión!');
                                    setTimeout(() => setMessageSubmit(''), 2500);
                                }
                            }}>Agregar al carrito</div>
                        }


                        <div className="productSubTitleDesc">Descripción</div>
                        <div className="productDescription">{item.description}</div>
                    </div>
                </div>

            </div>
            {messageSubmit !== '' &&
                <div className="toast__component">
                    <div className="add__cart--toast">
                        <div className="add__cart--text">{messageSubmit}</div>
                    </div>
                </div>
            }
        </div>

    )
};


export default ProductDetailScreen;