import React, { useContext, useEffect, useState } from "react";
import '../css/order.css';
import { Context as OrderContext } from '../context/OrderContext';
import { Context as AuthContext } from '../context/AuthContext';
import { Context as ConfigContext } from '../context/ConfigContext';
//import { Context as ProductContext } from '../context/ProductContext';
import { useNavigate } from "react-router-dom";
import logo from '../assets/logo.png';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { MdPendingActions, MdOutlineCreditCard, MdStoreMallDirectory, MdWeb } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";

const OrderScreen = () => {
    const {state:{rol, load}, tryLocalSignin} = useContext(AuthContext);
    const {state, readOrders, readOrdersUsers, deleteOrder} = useContext(OrderContext);
    //const {state:{Product, baseURL}, readProductAll} = useContext(ProductContext);
    const {state:{business, secure_perc, secure_cant}, readConfig} = useContext(ConfigContext);

    const [search, setSearch] = useState('');
    const [searchShow, setSearchShow] = useState(false);
    const [filter, setFilter] = useState('');
    const [viewMode, setViewMode] = useState(0);
    const [options, setOptions] = useState(rol === null || rol === 'User' ? 1 : 0);
    const [page, setPage] = useState(0);
    const date = new Date();
    date.setDate(1);
    date.setHours(0,0,0);
    date.setMonth(parseInt(date.getMonth()) + page);
    let render = false;

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        if(render === false){
            ReadFunction(options);
        }
        if(load === false){
            //navigate("/", { replace: true });
            tryLocalSignin({business_id:2});
            readConfig(2);
        }
        render = true;
    },[]);

    

    const monthNames = ['','Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre',
      'Octubre','Noviembre','Diciembre'];

    const start = new Date(date);
    
    const end = new Date(start);
    end.setMonth(end.getMonth() + 1);

    let Order = []; //options === 0 ? state.PendingOrders : state.Orders;
    switch(options){
        case 0:
            Order = state.Orders.filter(val => val.status === 'Pendiente');
        break;
        
        case 1:
            if(rol === 'User'){
                if(filter){
                    Order = state.Orders.filter(val => val.status === filter);
                }else{
                    Order = state.Orders.filter(val => val.status !== undefined) ;
                }
            }else{
                if(filter){
                    Order = state.Orders.filter(val => val.status === filter &&
                        (new Date(val.delivery) >= start && new Date(val.delivery) < end));
                }else{
                    Order = state.Orders.filter(val => new Date(val.delivery) >= start 
                        && new Date(val.delivery) < end);
                }
            }
            break;
       
        
    }
    if(!rol){
        Order = [];
    }

    const ReadFunction = (opt) => {
        if(rol === 'User'){
            readOrdersUsers();
        }else if(rol === 'Support' || rol === 'Admin' || rol === 'Super'){
            readOrders({rol, 
                start:start.toISOString().substr(0,10)+' 00:00', 
                end:end.toISOString().substr(0,10)+' 00:00',
                options:opt
            }); 
        }
    };

    const RequestFunction = (p) => {
        setPage(p);
        let start = new Date();
        start.setDate(1);
        start.setHours(0,0,0);
        start.setMonth(parseInt(start.getMonth()) + p);
        let end = new Date(start);
        end.setMonth(end.getMonth() + 1);
        readOrders({rol, 
            start:start.toISOString().substr(0,10)+' 00:00', 
        end:end.toISOString().substr(0,10)+' 00:00',
        options});
    };

    return(
        <div className="App">
            <div className="navContainer">
                <div className="navBar">
                    {business.length > 0 && <div className="headerNameCenter">{business[0].name}</div>}
                    <div className="searchBar_Order" style={{ opacity: 1 }}>
                    
                    <div className="searchBar__input--wrapper">
                        <input
                        type="search"
                        className="searchBar__input"
                        id="searchBar__input"
                        value={search}
                        pattern=".{50,}"
                        onChange={(e) => setSearch(e.target.value)}
                        ></input>
                        <div className="searchBar__line"></div>
                        <label htmlFor="searchBar__input" className="searchBar__placeholder">
                        Busque aqui...
                        </label>
                    </div>

                    </div>
                </div>
            </div>
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon"/>
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title">Pedidos</div>
                </div>
            </div>
            <div className="order_list">
                {rol !== null && rol !== 'User' &&
                <div className="order_filter">
                    <div className="order_filter_box">
                        <div className="order_box_row order_margin_button">
                            <MdPendingActions className="order_icon_Tab" />
                            <div className={options === 0  ? "order_filter_selected" : "order_filter_label"}
                                onClick={() => {setOptions(0); ReadFunction(0);}}>Pendientes {options === 0 ? Order.length : ''}</div>
                        </div>
                        <div className="order_box_row order_margin_button">
                            <MdOutlineCreditCard className="order_icon_Tab" />
                            <div className={options === 1  ? "order_filter_selected" : "order_filter_label"}
                                onClick={() => {setOptions(1); ReadFunction(1);}}>Todos {options === 1 ? Order.length : ''}</div>
                        </div>
                        
                    </div>
                </div>
                }
                <div className="order_card">

                {options === 0 || options === 2 || rol === null || rol === 'User'
                ? null
                : 
                <div className="order_box_row order_box_center">
                    <BsChevronLeft className="order_icon" onClick={() => RequestFunction(page-1)} />
                        <div className="order_date">{monthNames[(date.getMonth() + 1)]}</div>
                    <BsChevronRight className="order_icon" onClick={() => RequestFunction(page+1)}/>
                </div>
                }

                    {Order.length === 0 &&
                    <div className="order_empty_box">
                        <div className="order_empty_text">No has realizado ningún pedido</div>
                    </div>
                    }

                    {Order.filter((item) => {
                        const itemData = item.name.toUpperCase()+' '+item.lastname.toUpperCase()+
                        ' '+item.delivery.substr(0,10).toUpperCase()+' '+item.id;
                        const textData = search.toUpperCase();
                        return itemData.indexOf(textData) > -1;})
                    .map((item,key) => {
                        var fecha = new Date(item.delivery);
                        let totalItems = item.items.reduce((sum, val) => sum + val.items,0);
                        //let findImage2 = {images:[]};
    
                        let totalPrice = item.items.reduce((sum, value) => 
                        (sum + ((value.price - ((value.price * value.discount)/100)) * value.items)), 0);
                        totalPrice = item.discount != null
                        ? (totalPrice - ((totalPrice * item.discount)/100))
                        : totalPrice;
                        
                        
                        if(item.discount_money !== null){
                            totalPrice = totalPrice - item.discount_money;
                        }   
                        totalPrice += item.services;
    
                        if(item.secure){
                            totalPrice = parseFloat(totalPrice) + (((parseFloat(totalPrice) * secure_perc) / 100) + secure_cant);
                        }

                        return(
                            <div key={key} className="order_box" onClick={() => 
                                navigate("/order_detail",{state:{id:item.id, rol, status:item.status}})}>
                                <div className="order_box_image">
                                    <img src={logo} alt={''} className="order_image"/>
                                </div>
                                <div className="order_box_par">
                                    {totalItems > 0 &&
                                    <div className="order_box_text">{totalItems.toFixed(0)} {totalItems === 1 ? ' pz ' : 'pzs'}</div>
                                    }
                                </div>
                                <div className="order_box_info">
                                    <div className="order_box_row">
                                        <div className="order_box_text" >Folio:</div>
                                        <div className="order_box_text order_box_bold" style={{paddingLeft:5}}>{item.id}</div>
                                    </div>
                                    <div className="order_box_text">{item.name} {item.lastname}</div>
                                    <div  className={item.status === 'Pendiente' ? "order_box_text order_orange" 
                                        : item.status === 'Cancelado' ? "order_box_text order_red" : 
                                        item.status === 'En revisión' ? "order_box_text" : 
                                        item.status === 'Pendiente de pago' 
                                            ? "order_box_text order_orange" : "order_box_text order_green"} >{item.status}</div>
                                    {/*<div className="order_box_text">Pago: {item.payment_status}</div>*/}
                                    <div className="order_box_text">{fecha.toString().substr(4,17)}</div>
                                    <div className="order_box_text">${parseFloat(totalPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+' MXN'}</div>
                                </div>
                            </div>
                        )
                    })}

                </div> 
            </div>
        </div>
    );
};

export default OrderScreen;