import React, { useState } from "react";
import { useNavigate} from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";

const AddressForm = ({ item, onSubmit, nav, account_id }) => {
    const navigate = useNavigate();
    const [form, setForm] = useState(item);
    const [message, setMessage] = useState({});
    const [showActivity, setShowActivity] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            form.account_id = account_id != null ? account_id.id : null;
            onSubmit(form, nav, setShowActivity);
            
        } catch (error) {

        }
    };
    
    const handleChange = ({ target: { value, name } }) =>
    setForm({ ...form, [name]: value });

    return(
        <div className="App">
            <div className="navContainer">
                <div className="navBar">
                <div className="headerNameCenter">Mauricio Mares</div>
                
                </div>
            </div>
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon"/>
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/account', { replace: true })}>Cuenta</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/address', { replace: true })}>Mis direcciones</div>
                </div>
            </div>
            <div className="list">
                <div className="signupCard">
                    <form
                        onSubmit={handleSubmit}   
                    >
                        <div>
                            <input
                                type="text"
                                name="state1"
                                id="state1"
                                value={form.state1}
                                onChange={handleChange}
                                placeholder="Estado"
                                className="inputSignup"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                name="city"
                                id="city"
                                value={form.city}
                                onChange={handleChange}
                                placeholder="Ciudad"
                                className="inputSignup"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                name="suburb"
                                id="suburb"
                                value={form.suburb}
                                onChange={handleChange}
                                placeholder="Colonia"
                                className="inputSignup"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                name="street"
                                id="street"
                                value={form.street}
                                onChange={handleChange}
                                placeholder="Calle"
                                className="inputSignup"
                                required
                            />
                        </div>

                        <div>
                            <input
                                type="text"
                                name="num_ext"
                                id="num_ext"
                                value={form.num_ext}
                                onChange={handleChange}
                                placeholder="Número Exterior"
                                className="inputSignup"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                name="num_int"
                                id="num_int"
                                value={form.num_int}
                                onChange={handleChange}
                                placeholder="Número Interior"
                                className="inputSignup"
                                
                            />
                        </div>
                        <div>
                            <input
                                type="number"
                                name="cp"
                                id="cp"
                                value={form.cp}
                                onChange={handleChange}
                                placeholder="Código Postal"
                                className="inputSignup"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                name="reference"
                                id="reference"
                                value={form.reference}
                                onChange={handleChange}
                                placeholder="Referencia"
                                className="inputSignup"
                            />
                        </div>

                        <div>
                        {showActivity && <div class="loader"></div>}

                            <button
                                type="submit"
                                className="button"
                            >
                                Guardar
                            </button>
                        </div>
                    </form>     
                </div>
            </div>       
      </div>
    );
};

export default AddressForm;