import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { Context as AuthContext } from '../context/AuthContext';
import { Context as OrderContext } from '../context/OrderContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import sha256 from 'crypto-js/sha256';
import ExportPDF from '../components/ExportPDF';

const AuthLoadScreen = () => {
    const navigate = useNavigate();
    const { state: { UserData } } = useContext(AuthContext);
    const { state: { GoogleItems, printOrder, printId } } = useContext(OrderContext);
    const { state: { business } } = useContext(ConfigContext);


    async function hashString(str) {
        const encoder = new TextEncoder();
        const data = encoder.encode(str);
        const hashBuffer = await crypto.subtle.digest("SHA-256", data);
        const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
        return hashHex;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => navigate("/products", { replace: true }), 2500);

        if (GoogleItems !== null) {
          

            let ItemsPur = [];
            let arrayItems = [];
           

            window.fbq('track', 'Purchase', {
                content_ids: arrayItems,
                value: parseFloat(GoogleItems.amount),
                currency: 'MXN',
                content_type: 'product',
            });
        }

    }, []);

    return (
        <div className="App">
            <div className="no-print">
                <div className="confirmation_card_confirm_up">
                    <div className="confirmation_confirm_box">
                        <BsFillCheckCircleFill size={54} color='#fff' />
                        <div className="confirmation_confirm_text">Gracias por tu compra!</div>
                    </div>
                </div>
                <div className="confirmation_card_confirm_down">

                </div>
            </div>
            {printOrder &&
                <ExportPDF id={printId} auto={true} />
            }
        </div>
    )

};

export default AuthLoadScreen;